.navbar {
    background-color: transparent !important;
}


.logo {
    text-transform: uppercase;
    /* margin-left: 20%; */
}

.logo p {
    font-size: 1.8rem;
    font-weight: bold;
    -webkit-text-stroke: 2px #0074E4;
    /* text-stroke: 2px #0074E4; */
    color: transparent;
    text-align: center;
    margin-left: -20px;
    margin-bottom: -2px;
}

.logo h3 {
    font-weight: bold;
    -webkit-text-stroke: 2px #4CAF50;
    /* text-stroke: 2px #4CAF50; */
    color: transparent;
    margin-left: -19px;
}

.logo h5 {
    font-weight: bold;
    -webkit-text-stroke: 2px #FFD700;
    /* text-stroke: 2px #FFD700; */
    color: transparent;
    margin-top: -12px;
    margin-left: 19px;
}

ul {
    margin: 10;
    padding: 0;
    display: flex;
    list-style-type: none;
}

ul li .nav-link {
    color: #f3f3f3;
    font-size: 1rem;
    text-decoration: none;
    text-transform: uppercase;
    padding: 5px 10px;
    margin: 0px 10px;
    position: relative;
    transition: all 0.5s;
}

.nav-link {
    cursor: pointer;
    display: inline-block;
    position: relative;
    overflow: hidden;
}

.nav-link::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: dodgerblue;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s;
}

.nav-link:hover::before {
    transform: scaleX(1);
    transform-origin: left;
}

.dropdown-item:hover {
    background-color: #5e6a75;
}

