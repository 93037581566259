 #particles-js {
     height: 100vh;
     width: 100%;
     position: fixed;
     z-index: -1 !important;
     background-color: #212529;
 }

 .navs {
     background-color: transparent !important;
     backdrop-filter: blur(5px);
 }

 .f-link {
     text-decoration: none;
     color: #ffff;
 }

 .f-link:hover {
     color: rgba(255, 255, 255, 0.75);
 }

 .img-bn {
     filter: invert(96%) sepia(96%) saturate(0%) hue-rotate(90deg) brightness(105%) contrast(106%);
 }

 .text-width {
     font-size: 5px !important;
 }

 .bg-color {
     background-color: #212529 !important;
 }

 .repositorios {
     margin-left: -10px;
 }

 @media screen and (max-width: 900px) {
     .bg-color {
         width: 100%;
     }

     .repositorios {
         width: 106%;
         margin-left: -10px;
     }
 }