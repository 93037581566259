html,
body {
    width: auto;
    height: 100%;
}

.bg-dark {
    background-color: #08090B !important;
}

.bg-particles-maintenance{
    background-color: #08090B!important;
}

#particles-js {
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: -1 !important;
}